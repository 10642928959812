/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
import { ref, watch } from '@vue/composition-api'
import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'

export default function useImageUpload() {
  const imageFile = ref(null)
  const imageUrl = ref(null)
  const fileSize = ref(null)
  const errors = ref([])
  const fileName = ref(null)
  const uploadProcess = ref(false)
  const { errorToast, successToast } = useAlertNotification()

  const uploadFile = async data => {
    try {
      const response = await axiosClient.post('/upload-file', data)
      fileName.value = response.data.image
      successToast('Fichier chargé avec succès')
    } catch (error) {
      errors.value = ''
      errors.value = error.response.data.errors
      const uploadFileErrors = error.response.data.errors
      Object.keys(uploadFileErrors).forEach(key => {
        errorToast(uploadFileErrors[key][0])
      })
    }
  }
  const uploadFilePdf = async data => {
    try {
      const response = await axiosClient.post('/upload-file-pdf', data)
      fileName.value = response.data.piece
      successToast('Fichier chargé avec succès')
    } catch (error) {
      errors.value = ''
      errors.value = error.response.data.errors
      const uploadFileErrors = error.response.data.errors
      Object.keys(uploadFileErrors).forEach(key => {
        errorToast(uploadFileErrors[key][0])
      })
    }
  }
  const deleteFile = async fileName => {
    await axiosClient.delete(`/remove-file/${fileName}`)
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        console.log(error)
      })
  }
  const deleteFileSpecificFolder = async (fileName, folder) => {
    await axiosClient.delete(`/remove-file-specific-folder/${fileName}/${folder}`)
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleImageSelected = event => {
    uploadProcess.value = true
    if (event.target.files.length === 0) {
      imageFile.value = null
      imageUrl.value = null
      return
    }

    imageFile.value = event.target.files[0]
    fileSize.value = event.target.files[0].size

    setTimeout(() => {
      uploadProcess.value = false
    }, 1000)
  }

  watch(imageFile, imageFile => {
    if (!(imageFile instanceof File)) {
      return
    }

    const fileReader = new FileReader()

    fileReader.readAsDataURL(imageFile)

    fileReader.addEventListener('load', () => {
      imageUrl.value = fileReader.result
    })
  })

  return {
    imageFile,
    uploadProcess,
    imageUrl,
    handleImageSelected,
    deleteFileSpecificFolder,
    uploadFile,
    fileName,
    deleteFile,
    fileSize,
    uploadFilePdf,
  }
}
