import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'

export default function useDepartements() {
  const departement = ref([])
  const communes = ref([])
  const loader = ref(false)
  const departementProcess = ref(false)
  const departementSuccess = ref(false)
  const departements = ref([])
  const errors = ref('')

  // Liste des departements
  const getDepartements = async () => {
    loader.value = true
    const response = await axiosClient.get('/departements')
    try {
      if (response.data.success === true) {
        loader.value = false
        departements.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  const getDepartementById = async id => {
    const response = await axiosClient.get(`/departements/${id}`)
    departement.value = response.data.data
    communes.value = response.data.data.communes
  }

  return {
    errors,
    departement,
    departements,
    getDepartements,
    departementProcess,
    communes,
    loader,
    departementSuccess,
    getDepartementById,
  }
}
