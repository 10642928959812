<template>
  <b-row>

    <b-col
      v-if="mairies.length"
      md="12"
    >
      <b-card
        title="Liste des mairies"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1 ml-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
              <b-button
                v-b-modal.add-modal
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                size="sm"
                class="float-right mb-1"
              >
                Créer une mairie
                <feather-icon icon="PlusCircleIcon" />
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="mairies"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-center">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.bottom="'Choisir un administrateur'"
                v-b-modal.designate-admin
                variant="success"
                size="sm"
                class="btn-icon rounded-circle"
                @click.prevent="getMairie(data.item)"
              >
                <feather-icon icon="ShieldIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.bottom="'Liste des agents de la mairie'"
                variant="dark"
                size="sm"
                :to="{ name: 'espace-super-admin.detail-mairie', params: { slug: data.item.slug } }"
                class="btn-icon rounded-circle ml-1"
              >
                <feather-icon icon="ListIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.bottom="'Modifier une mairie'"
                v-b-modal.update-modal
                variant="primary"
                size="sm"
                class="btn-icon rounded-circle ml-1"
                @click.prevent="getMairie(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.bottom="'Supprimer une mairie'"
                v-b-modal.suppression
                variant="danger"
                size="sm"
                class="btn-icon rounded-circle ml-1"
                @click.prevent="getMairie(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>

            </div>
          </template></b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="mairies.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>
    <b-col
      v-else
      md="12"
    >
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1 ml-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
              <b-button
                v-b-modal.add-modal
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                size="sm"
                class="float-right mb-1"
              >
                Créer une mairie
                <feather-icon icon="PlusCircleIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
        <b-card-body>
          <b-row>
            <b-col md="6">
              <b-img
                :src="require('@/assets/images/original.png')"
                class="shadow"
                style="height:200px !important"
                alt=""
                srcset=""
              />
            </b-col>
            <b-col
              md="6"
              class="mt-5"
            >
              <span class="text-center">
                Nous n'avons pas trouvé de résultats à afficher
              </span>
            </b-col>
          </b-row>

        </b-card-body>

      </b-card>
    </b-col>
    <b-modal
      id="add-modal"
      ref="my-modal"
      centered
      no-close-on-backdrop
      button-size="sm"
      title="Créer une mairie"
      :busy="mairieProcess"
      ok-title="Créer"
      cancel-title="Fermer"
      cancel-variant="secondary"
      ok-variant="success"
      @show="resetModal"
      @ok="handleOk"
    >
      <validation-observer ref="ValidationRules">
        <form @submit.prevent="handleSubmitModal">
          <b-form-group
            label="Département"
            label-for="departement_id"
          >
            <validation-provider
              #default="{ errors }"
              name="département"
              rules="required"
            >
              <v-select
                id="departement_id"
                v-model="form.departement_id"
                label="name"
                :state="errors.length > 0 ? false : null"
                :options="departements"
                placeholder="Choisir un département"
                :reduce="(departements) => departements.id"
                class="select-size-sm"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Communes"
            label-for="commune"
          >

            <validation-provider
              #default="{ errors }"
              name="commune"
              rules="required"
            >
              <v-select
                id="commune"
                v-model="form.commune_id"
                label="name"
                :state="errors.length > 0 ? false : null"
                :options="communes"
                placeholder="Choisir une commune"
                :reduce="(communes) => communes.id"
                class="select-size-sm"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Téléphone"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="téléphone"
              rules="required"
            >
              <VuePhoneNumberInput
                v-model="form.telephonefull"
                default-country-code="BJ"
                show-code-on-list
                size="sm"
                :translations="configPhoneNumber"
                @update="telephonefull = $event"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Logo"
            label-for="fichier"
            label-size="sm"
          >
            <validation-provider
              #default="{ errors }"
              name="logo"
              rules="required"
            >
              <b-form-file
                id="fichier"
                ref="file-input"
                v-model="form.image"
                accept="image/*"
                size="sm"
                placeholder="Choisir un fichier"
                @change="handleImageSelected"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <b-button
                v-if="uploadProcess"
                variant="success"
                disabled
                block
                size="sm"
                class="mt-1"
              >
                <b-spinner small />
                Chargement...
              </b-button>
            </validation-provider>
          </b-form-group>
        </form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="update-modal"
      ref="my-modal"
      centered
      no-close-on-backdrop
      button-size="sm"
      title="Modifier une mairie"
      :busy="mairieProcess"
      ok-title="Modifier"
      cancel-title="Fermer"
      cancel-variant="secondary"
      ok-variant="success"
      @ok="handleOk"
    >
      <validation-observer ref="ValidationRules">
        <form @submit.prevent="handleSubmitModal">

          <b-form-group
            label="Département"
            label-for="departement_id"
          >
            <validation-provider
              #default="{ errors }"
              name="département"
              rules="required"
            >
              <v-select
                id="departement_id"
                v-model="formUpdate.departement_id"
                label="name"
                :state="errors.length > 0 ? false : null"
                :options="departements"
                placeholder="Choisir un département"
                :reduce="(departements) => departements.id"
                class="select-size-sm"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Communes"
            label-for="commune"
          >

            <validation-provider
              #default="{ errors }"
              name="commune"
              rules="required"
            >
              <v-select
                id="commune"
                v-model="formUpdate.commune_id"
                label="name"
                :state="errors.length > 0 ? false : null"
                :options="communes"
                placeholder="Choisir une commune"
                :reduce="(communes) => communes.id"
                class="select-size-sm"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Téléphone"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="téléphone"
              rules="required"
            >
              <VuePhoneNumberInput
                v-model="formUpdate.telephonefull"
                default-country-code="BJ"
                show-code-on-list
                size="sm"
                :translations="configPhoneNumber"
                @update="telephonefull = $event"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Logo"
            label-for="fichier"
            label-size="sm"
          >
            <validation-provider
              #default="{ errors }"
              name="logo"
              rules="required"
            >
              <b-form-file
                id="fichier"
                ref="file-input"
                v-model="formUpdate.image"
                accept="image/*"
                size="sm"
                placeholder="Choisir un fichier"
                @change="handleImageSelected"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <b-button
                v-if="uploadProcess"
                variant="success"
                disabled
                block
                size="sm"
                class="mt-1"
              >
                <b-spinner small />
                Chargement...
              </b-button>
            </validation-provider>
          </b-form-group>
        </form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="suppression"
      ref="my-modal"
      cancel-title="Non"
      ok-title="Oui"
      title="Suppression de mairie"
      modal-class="modal-danger"
      ok-variant="danger"
      button-size="sm"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleDeleteOk"
    >
      <form @submit.prevent="handleDeleteSubmitModal">
        <h6>Etes-vous sûr de vouloir supprimer cette mairie?</h6>

      </form>
    </b-modal>
    <b-modal
      id="designate-admin"
      ref="my-modal"
      cancel-title="Fermer"
      ok-title="Valider"
      title="Désignation de responsable"
      modal-class="modal-success"
      ok-variant="success"
      centered
      button-size="sm"
      cancel-variant="secondary"
      no-close-on-backdrop
      @show="resetModal"
      @ok="handleConfirmOk"
    >
      <validation-observer ref="designateAdminRules">
        <form
          ref="form"
          @submit.prevent="handleConfirmSubmitModal"
        >

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="de sélection"
              rules="required"
            >
              <v-select
                id="user_id"
                ref="id-input"
                v-model="formDesignateAdmin.user_id"
                label="name"
                :state="errors.length > 0 ? false : null"
                placeholder="Choisir l'administrateur"
                :options="users"
                :reduce="(users) => users.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
        </form>
      </validation-observer>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
// eslint-disable-next-line import/no-extraneous-dependencies
import VuePhoneNumberInput from 'vue-phone-number-input'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  ref, onMounted, reactive, watch,
} from '@vue/composition-api'
import { required } from '@validations'
import {
  BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BFormInput,
  BSpinner, VBTooltip, VBModal, BModal, BImg, BFormFile,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useMairies from '@/services/super-admin/mairieService'
// eslint-disable-next-line import/no-cycle
import useUsers from '@/services/super-admin/userService'
// eslint-disable-next-line import/no-cycle
import useDepartements from '@/services/super-admin/departementService'
// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/services/upload/useImageUpload'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    VuePhoneNumberInput,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormFile,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BSpinner,
    BModal,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BImg,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,

    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    // eslint-disable-next-line no-undef
    const {
      mairies, loader, getListeMairies, mairieProcess, mairieSuccess, storeOrUpdateMairie, deleteMairie,
    } = useMairies()
    const {
      users, getOnlyUsersRoleUtilisateur, designateAdministrator, userSuccess,
    } = useUsers()
    const {
      getDepartements, getDepartementById, departement, departements, communes,
    } = useDepartements()
    const {
      imageFile,
      handleImageSelected,
      uploadFile,
      fileName,
      deleteFile,
      uploadProcess,
    } = useImageUpload()
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'mairie_name', label: 'Nom', sortable: true },
      { key: 'agents_count', label: 'Nombre Agents', sortable: true },
      { key: 'actions' },
    ]
    const form = reactive({
      telephonefull: '',
      numero_full: '',
      numero: '',
      image: null,
      logo: null,
      departement_id: null,
      commune_id: null,
    })
    const formUpdate = reactive({
      id: null,
      telephonefull: '',
      numero_full: '',
      numero: '',
      image: null,
      logo: null,
      departement_id: null,
      commune_id: null,
    })
    const formDesignateAdmin = reactive({
      mairie_id: '',
      user_id: '',
    })
    const resetModal = () => {
      formUpdate.id = null
      formUpdate.telephonefull = ''
      formUpdate.numero_full = ''
      formUpdate.numero = ''
      formUpdate.image = null
      formUpdate.logo = ''
      formUpdate.departement_id = null
      formUpdate.commune_id = null
      form.telephonefull = ''
      form.numero_full = ''
      form.numero = ''
      form.image = null
      form.logo = ''
      form.departement_id = null
      form.commune_id = null
      formDesignateAdmin.user_id = ''
    }
    const currentDepartement = async data => {
      console.clear()
      departement.value = ''
      await getDepartementById(data)
    }
    const oldImage = ref(null)
    const upload = async () => {
      const result = imageFile.value instanceof File
      if (result) {
        const data = new FormData()
        data.append('fichier', imageFile.value)
        await uploadFile(data)
        form.logo = fileName.value
        await deleteFile(oldImage.value)
      }
    }

    watch(() => _.cloneDeep(form.departement_id), value => {
      communes.value = []
      currentDepartement(value)
    })
    watch(() => _.cloneDeep(formUpdate.departement_id), value => {
      communes.value = []
      currentDepartement(value)
    })
    const telephonefull = ref('')
    watch(() => (form.telephonefull), () => {
      form.numero = ''
      form.numero_full = ''
      form.numero_full = telephonefull.value.formattedNumber
      form.numero = form.telephonefull
      console.clear()
    })
    onMounted(async () => {
      await getListeMairies()
      await getOnlyUsersRoleUtilisateur()
      await getDepartements()
      // Set the initial number of items
      totalRows.value = mairies.value.length
    })
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    // Obtenir une mairie
    const getMairie = async item => {
      formUpdate.telephonefull = item.numero
      formUpdate.numero_full = item.numero_full
      formUpdate.numero = item.numero
      formUpdate.image = item.logo
      oldImage.value = item.logo
      formUpdate.logo = item.logo
      formUpdate.departement_id = item.departement_id
      formUpdate.commune_id = item.commune_id
      formUpdate.id = item.id
      formDesignateAdmin.mairie_id = item.id
    }
    const selectAdmin = async () => {
      await designateAdministrator({ ...formDesignateAdmin })
      await getListeMairies()
      if (userSuccess.value) resetModal()
    }
    const store = async () => {
      await upload()
      if (formUpdate.id !== null) storeOrUpdateMairie({ ...formUpdate })
      else storeOrUpdateMairie({ ...form })

      if (mairieSuccess.value) resetModal(); await getListeMairies(); await getOnlyUsersRoleUtilisateur()
    }
    const deleteOneMairie = async () => {
      await deleteMairie(formDesignateAdmin.mairie_id)
      resetModal()
    }
    const formatter = value => value.toUpperCase()
    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      departements,
      communes,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      handleImageSelected,
      fields,
      onFiltered,
      form,
      mairieProcess,
      telephonefull,
      selectAdmin,
      mairieSuccess,
      formUpdate,
      getMairie,
      formDesignateAdmin,
      userSuccess,
      uploadProcess,
      formatter,
      store,
      users,
      loader,
      mairies,
      deleteOneMairie,
      resetModal,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    handleConfirmOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleConfirmSubmitModal()
    },
    handleDeleteOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleDeleteSubmitModal()
    },

    async handleDeleteSubmitModal() {
      // Exit when the form isn't valid
      await this.deleteOneMairie()
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.$refs.ValidationRules.validate().then(async success => {
        if (success) {
          // Push the name to submitted names
          await this.store()
          if (this.mairieSuccess) {
            this.$nextTick(() => {
              this.$refs['my-modal'].toggle('#toggle-btn')
            })
          }
        }
      })
    },
    handleConfirmSubmitModal() {
      // Exit when the form isn't valid
      this.$refs.designateAdminRules.validate().then(async success => {
        if (success) {
          await this.selectAdmin()
          if (this.userSuccess) {
            this.$nextTick(() => {
              this.$refs['my-modal'].toggle('#toggle-btn')
            })
          }
        }
      })
    },

  },

}

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
